.vacancy {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.vacancy-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &:hover > .vacancy-btns {
    visibility: visible;
    opacity: 1;
  }

  .vacancy-btns {
    display: flex;
    align-items: center;
    gap: 20px;
    visibility: hidden;
    opacity: 0;
    padding: 20px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    transition: all 0.6s ease-in-out;
    border-radius: 0.2rem;
  }
}

.vacancy-detail {
  hr {
    width: 200px;
    margin: 15px auto;
  }
}
