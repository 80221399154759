.events-request {
  display: block;
  margin: 0 auto 1.5em;
  background: none;
  outline: none;
  border: none;

  color: #0d6efd;
  text-transform: uppercase;
}

.events {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  &.profile {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    .event-img img {
      height: 150px;
    }
  }

  @media (max-width: 440px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  .event-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: transform 0.3s ease-in-out;
  }

  .event-img img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }

  .event-content {
    padding: 10px;
    text-align: center;

    .event-title {
      color: inherit;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;

      &:hover {
        color: #0a58ca;
      }
    }

    .event-date {
      margin: 10px 0;
    }
  }

  .event-btns {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    row-gap: 10px;
    column-gap: 10px;

    padding: 0 10px 10px;
  }

  .event-btn {
    background: none;
    border: none;
    outline: 1px solid #0a58ca;
    color: #0a58ca;
    font-size: 14px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px 0;
    text-transform: uppercase;
    text-align: center;

    span {
      display: block;
      font-size: 12px;
      text-transform: none;
    }
  }
}

.event-form {
  hr {
    width: 300px;
    max-width: 80%;
    height: 2px;
    border: none;
    background-color: #d4d4d5;
    margin: 1.5em auto;
  }
}

.object-fit-contain {
  object-fit: contain !important;
}
