.managers {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  .manager-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: transform 0.3s ease-in-out;
    text-align: center;
  }

  .manager-item__organization,
  .manager-item__email,
  .manager-item__name {
    margin-bottom: 10px;
  }

  .manager-item__btns {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    row-gap: 10px;
    column-gap: 10px;
  }
}
