.plyr {
  width: 600px;
  height: 100%;
  margin: 30px auto 0;
}

.success-story-detail__wrapper {
  @media (min-width: 768px) {
    min-height: 605px;
  }
}

.success-story-detail {
  background-color: #fff;
  //   filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1em;
  border-radius: 0.2rem;
  position: relative;

  .success-story-content {
    margin-top: 15px;
    line-height: 25px;
    word-spacing: 4px;
    white-space: pre-line;
  }

  .success-story-title {
    text-align: center;
    font-weight: 600;
    margin: 10px 0 0;

    span {
      font-size: 16px;
      font-weight: 100;
    }
  }

  hr {
    width: 200px;
    margin: 25px auto 15px;
  }

  svg {
    height: 22px;
    width: 22px;
    margin-right: 5px;
  }

  .success-story-date {
    text-align: center;
  }

  &:before {
    background-color: #fff;
    visibility: visible;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    transform: rotate(-1.2deg);
    top: 0;
    left: 0;
    z-index: -2;
    border: 1px solid rgba(34, 36, 38, 0.15);
  }

  &:after {
    content: '';
    background-color: #fff;
    visibility: visible;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    transform: rotate(1.2deg);
    top: 0;
    left: 0;
    z-index: -1;
    border: 1px solid rgba(34, 36, 38, 0.15);
  }

  @media (max-width: 400px) {
    overflow: hidden;
  }
}

.success-story-img.detail {
  margin: 2rem auto 0;
  width: fit-content;
  position: relative;

  img {
    height: 300px;
    object-fit: cover;
    border-radius: 0.2rem;
  }
}
