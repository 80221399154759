:root {
  --bs-body-font-family: 'Rubik', sans-serif;
}
a {
  color: #264049;
}
body {
  background: rgba(248, 249, 250, 1);
}
.page-link {
  color: #264049;
}
.page-item.active .page-link {
  background-color: #264049;
  border-color: #264049;
}

.btn-primary,
.btn-outline-primary {
  border-color: #264049;
}
.btn-primary {
  background-color: #264049;
}
.btn-primary:hover {
  background-color: #1d3037;
  border-color: #264049;
}
.btn-outline-primary {
  color: #264049;
}
.btn-outline-primary:hover {
  background-color: #264049;
  border-color: #264049;
}

.box-shadow {
  -webkit-box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.03);
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.section {
  flex-grow: 1;
}

.section-title {
  text-align: center;
  position: relative;

  h1 {
    margin: 0;
  }

  hr {
    width: 300px;
    max-width: 80%;
    height: 2px;
    border: none;
    background-color: #d4d4d5;

    margin: 1.5em auto;
  }
}

a {
  text-decoration: none;
}

.filters-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  @media (max-width: 768px) {
    position: unset;
    justify-content: center;
    margin-bottom: 1.5em;
  }

  .filters {
    color: #fff;

    padding: 7px 20px;
    background-color: #f2711c;

    border: 1px solid #f2711c;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    svg {
      color: #fff;
      opacity: 0.8;
      margin-right: 10px;
    }
  }

  span {
    padding: 7px;
    color: #f2711c;
    font-weight: 600;
    position: relative;

    border: 1px solid #f2711c;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    &::after {
      content: '';
      background-color: #fff;
      width: 0.6666em;
      height: 0.6666em;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      color: #fff;
      position: absolute;
      top: 50%;
      left: 0px;
    }
  }

  &:hover {
    .filters {
      background-color: #f26202;
    }

    span {
      color: #f26202;
    }
  }
}

.requests {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.border-light {
  border-color: #eee !important;
}
