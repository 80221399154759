.section-login {
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 268px) !important;
}

.auth {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;

  img {
    width: 100%;
  }

  .auth-form {
    position: relative;

    svg {
      position: absolute;
      left: 10px;
      top: 12px;
      width: 15px;
      opacity: 0.5;
    }

    input {
      padding-left: 32px;
    }
  }

  .auth-form__group {
    position: relative;
  }

  @media (max-width: 768px) {
    .row {
      flex-direction: column;
    }

    img {
      width: 300px;
    }
  }
}
