.professional-objects {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  .professional-objects__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: transform 0.3s ease-in-out;
  }

  .professional-objects__item-name {
    text-align: center;
    margin: 0;
    padding: 20px 10px;
  }

  .professional-objects__item-img {
    max-height: 250px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }

  .professional-objects__item-btns {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    row-gap: 10px;
    column-gap: 10px;

    padding: 0 10px 10px;
  }
}
