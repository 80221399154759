.skills-btns {
  margin: 0 auto 1.5em;
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .skills-btn {
    background: none;
    border: none;
    outline: none;

    color: #264049;

    text-transform: uppercase;

    &.back {
      margin-bottom: 10px;
    }

    &.request {
    }
  }
}

.skills {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  .skill-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: inherit;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
    border-radius: 0.2rem;

    padding: 10px;

    &:hover {
      transform: translateY(-5px);
    }
  }

  .skill-title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .skill-type {
    text-align: right;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}
