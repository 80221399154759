.navbar {
  background: #fff;
  min-height: 100px;
  border-bottom: 1px solid #d4d4d5;

  .logo {
    height: 80px;
  }
}

.text-left {
  text-align: left;
}
