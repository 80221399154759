.buildings {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  .building-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: transform 0.3s ease-in-out;
    text-align: center;
  }

  .building-name,
  .building-phone,
  .building-address,
  .building-organization {
    margin-bottom: 10px;
  }

  .building-name {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .building-organization {
    font-size: 18px;
  }

  .building-btns {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    row-gap: 1rem;
    column-gap: 1rem;
  }
}
