.success {
  .success-story__item {
    position: relative;

    display: flex;
    align-items: center;
    border-radius: 0.2rem;
    background: #fff;

    padding: 15px;

    &:not(last-child) {
      margin-bottom: 20px;
    }

    @media (max-width: 990px) {
      display: block;
    }

    &:hover > .success-story__btns {
      visibility: visible;
      opacity: 1;
    }
  }

  .success-story__img {
    width: 100%;
    max-width: 350px;
    border-radius: 0.2rem;
    object-fit: cover;
  }

  .success-story__content {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 990px) {
      padding-left: 20px;
    }

    .success-story__title {
      text-align: center;
      margin-bottom: 1rem;

      @media (max-width: 990px) {
        margin-top: 1rem;
      }
    }

    .success-story__text {
      white-space: pre-line;
      margin: 0;
      // padding-bottom: 40px;
    }

    .success-story__btn {
      width: 100%;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.6);

      -webkit-box-shadow: 0 0 0 1px rgb(34 36 38 / 15%) inset;
      box-shadow: 0 0 0 1px rgb(34 36 38 / 15%) inset;

      border-radius: 0.2rem;
      padding: 0.5em 1.5em;

      transition: all 0.3s ease-in-out;

      &:hover {
        color: rgba(0, 0, 0, 0.8);

        -webkit-box-shadow: 0 0 0 1px rgb(34 36 38 / 55%) inset;
        box-shadow: 0 0 0 1px rgb(34 36 38 / 55%) inset;
      }

      // position: absolute;
      // bottom: 10px;
      // right: 10px;

      @media (max-width: 990px) {
        width: 100%;
        text-align: center;
      }
    }
  }

  .success-story__btns {
    display: flex;
    align-items: center;
    column-gap: 1rem;

    visibility: hidden;
    opacity: 0;

    padding: 1rem;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgb(0 0 0 / 70%);

    transition: all 0.6s ease-in-out;
  }
}
