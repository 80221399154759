.employers {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;

  .employer-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: transform 0.3s ease-in-out;
  }

  .employer-item__fio {
    text-align: center;
  }

  .employer-item__btns {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    row-gap: 1rem;
    column-gap: 1rem;
  }
}
