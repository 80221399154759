.groups {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.group-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 0.2rem;

  padding: 15px;
  text-align: center;
}

.group-item__program {
  margin: 10px 0;
}
