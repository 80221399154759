.form-registration {
  background: #fff;
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
  padding: 1em 1em;
  border-radius: 0.2rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  position: relative;

  &:after,
  &:before {
    background: fff;
    border: 1px solid #c4c6ca;
    border-radius: 0.2rem;
    content: '';
    display: block;
    height: 100%;
    left: -1px;
    position: absolute;
    width: 100%;
  }

  &:before {
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    transform: rotate(-3deg);
    top: 0;
    z-index: -2;
  }

  &:after {
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    -o-transform: rotate(2deg);
    transform: rotate(2deg);
    top: 0;
    z-index: -1;
  }
}
