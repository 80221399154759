.programm-detail {
  hr {
    width: 300px;
    max-width: 80%;
    height: 2px;
    border: none;
    background-color: #d4d4d5;
    margin: 1.5em auto;
  }
}

.programm-detail__title {
  text-align: center;
  margin-bottom: 1.5em;
}

.programm-detail__annotation {
  span {
    display: block;
    font-weight: 600;
  }

  margin-bottom: 10px;
}

.programm-detail__type {
  span {
    display: block;
    font-weight: 600;
  }

  margin-bottom: 10px;
}

.programm-detail__audience {
  span {
    display: block;
    font-weight: 600;
  }

  margin-bottom: 10px;
}

.programm-detail__modules-wrapper {
  h5 {
    text-align: center;
    margin-bottom: 1.5em;
  }

  svg {
    height: 1.3em;
    width: 1.3em;
    margin-right: 8px;
  }

  .programm-detail__modules {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;

    .module-item {
      background: #fff;
      transition: transform 0.3s ease-in-out;
      border-radius: 0.2rem;
      padding: 10px 15px;
    }

    .module-prof-area,
    .module-competence,
    .module-theme {
      margin-bottom: 5px;

      svg {
        height: 1em;
        width: 1em;
      }
      span {
        font-weight: 600;
      }
    }

    .module-price {
      svg {
        width: 1em;
        height: 1em;
      }
    }

    .module-title {
      font-size: 18px;
      text-align: center;
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
  }
}
