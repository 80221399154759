.aside {
  width: 225px;
}
.resumes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.resumes-item__position,
.resumes-item__experience,
.resumes-item__salary {
  margin-bottom: 10px;
}

.resumes-item__btns {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  gap: 10px;
}
