.booking-detail {
  display: grid;
  grid-template-columns: 40% 60%;
  // grid-column-gap: 20px;
  // grid-row-gap: 20px;

  margin-bottom: 2em;

  img {
    width: 100%;
    border-radius: 0.2rem;

    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }

  .booking-detail__content {
    font-size: 22px;

    @media (min-width: 992px) {
      margin-left: 20px;
    }
  }

  .booking-detail__type,
  .booking-detail__places,
  .booking-detail__competation,
  .booking-detail__address {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.2rem;
    padding: 10px;
    font-weight: 600;

    span {
      font-weight: 400;
      font-size: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: 100%;
  }
}

.booking-info {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.2rem;
  padding: 15px;
}

.booking-equipment {
  margin-top: 1.5em;

  h5 {
    margin-bottom: 1em;
  }
}

.booking-equipment_info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  h6 {
    margin-bottom: 0;
  }
}
.booking-equipment_item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  //&:not(:last-child) {
  margin-bottom: 10px;
  //}

  .booking-item__count input {
    width: 70px;
  }
}
