.event-detail__title {
  text-align: center;
}

.event-detail__description {
  word-break: break-word;
}

.event-detail__description,
.event-detail__location,
.event-detail__format,
.event-detail__people,
.event-detail__date {
  font-weight: 600;
  margin-bottom: 20px;

  span {
    font-weight: normal;
    display: block;
    padding: 10px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.2rem;

    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);

    margin-top: 10px;
  }
}

.event-detail__format {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;

  span {
    padding: 5px 10px;
    text-align: center;
  }
}

.event-detail__people {
  max-width: 100px;

  svg {
    height: 1.2em;
    width: 1.2em;
    margin-right: 5px;
  }
  span {
    background-color: #e8e8e8;
    text-align: center;
  }
}

.event-detail__date {
  max-width: 150px;

  span {
    text-align: center;
  }
}
