.booking {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  .booking-item {
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }
  }

  .booking-item__wrapper {
    display: block;
    color: inherit;
  }

  .booking-img {
    width: 100%;
    height: 260px;
    object-fit: cover;

    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    @media (max-width: 430px) {
      height: 200px;
    }
  }

  .booking-content {
    svg {
      margin-right: 10px;
    }
  }

  .booking-title {
    text-align: center;
    font-size: 20px;
    font-weight: 600;

    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}
