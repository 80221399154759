.programs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  @media (max-width: 440px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

.program-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  transition: transform 0.3s ease-in-out;
  border-radius: 0.2rem;

  img {
    height: 250px;
    width: 100%;
    object-fit: cover;

    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }

  &:hover {
    transform: translateY(-5px);
  }
}

.program-content {
  text-align: center;
  padding: 10px;

  .program-title {
    color: inherit;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;

    &:hover {
      color: #0a58ca;
    }
  }

  .program-audience {
    margin: 10px 0;
    span {
      font-weight: 500;
    }
  }
}

.program-btn {
  width: 100%;
  background: none;
  outline: 1px solid #0a58ca;
  border: none;
  font-size: 14px;
  text-align: center;

  color: #0a58ca;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  padding: 5px 0;

  text-transform: uppercase;

  span {
    display: block;
    font-size: 12px;
    text-transform: none;
  }

  &.auth {
    outline: 1px solid #00b5ad;
    color: #00b5ad;
  }
}
