.footer {
  padding: 20px 0;
  background-color: #333333;
  color: #fff;

  p {
    margin: 0;
  }

  .logo-copp {
    height: 120px;
  }

  .logo-kuzbass {
    height: 120px;
  }

  .footer-btn {
    display: inline-block;
    color: #fff;
    padding: 0 1rem;
    font-size: 1.5rem;
    border-radius: 0.2rem;

    &.vk {
      background-color: #45668e;

      //border-top-right-radius: 0.28571429rem;
    }
  }

  .col {
    &:not(:last-child) {
      border-right: 1px solid rgb(255 255 255 / 10%);
    }
  }
}
