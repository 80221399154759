.statistics {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  .statistics-item {
    text-align: center;
    border-radius: 0.2rem;
    padding: 15px;
    background: #fff;

    &:hover > .statistics-item__btns {
      visibility: visible;
      opacity: 1;
    }
  }

  .statistics-item__number {
    color: #264049;
    font-size: 3em;
    font-weight: bold;
  }

  .statistics-item__text {
    font-size: 18px;
  }

  .statistics-item__btns {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    visibility: hidden;
    opacity: 0;
    padding: 1rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: all 0.6s ease-in-out;
  }
}

.feedback-button {
  width: 60px;
  height: 60px;
  border: none;
  background-color: #264049;
  color: #fff;
  outline: none;
  border-radius: 100%;

  position: fixed;
  bottom: 10px;
  right: 10px;

  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 20%);

  z-index: 1000;

  svg {
    height: 100%;
  }
}

.partners-item {
  height: 200px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.2rem;
  }

  .partners-item__btns {
    display: flex;
    align-items: center;
    column-gap: 20px;
    visibility: hidden;
    opacity: 0;
    padding: 20px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.6s ease-in-out;
  }

  &:hover > .partners-item__btns {
    visibility: visible;
    opacity: 1;
  }
}
