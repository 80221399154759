.rooms {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  .room-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: transform 0.3s ease-in-out;
  }

  .room-item__img {
    height: 200px;
    border-radius: 0.2rem;
    object-fit: cover;
  }

  .room-item__name {
    text-align: center;
    margin: 10px 0;
  }

  .room-item__type {
    margin-bottom: 10px;
  }

  .room-item__btns {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    row-gap: 10px;
    column-gap: 10px;
  }
}
