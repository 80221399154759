.education-wrapper {
  .education-request {
    display: block;
    margin: 0 auto 1.5em;
    background: none;
    border: none;
    outline: none;

    color: #264049;

    text-transform: uppercase;
  }
}

.educations {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  @media (max-width: 440px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  .education-item {
    display: block;
    color: inherit;
    background: #fff;
    transition: transform 0.3s ease-in-out;
    border-radius: 0.2rem;

    .education-content {
      padding: 10px;
      text-align: center;

      .education-title {
        font-size: 20px;
        font-weight: 500;

        margin-bottom: 10px;
      }

      .education-professional-objects {
        font-weight: 400;
      }
    }

    &:hover {
      transform: translateY(-5px);
    }
  }

  .education-img img {
    max-height: 250px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}

.selected-programs__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  position: relative;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.selected-programs__item-img {
  height: 100px;
  margin-right: 0.7rem;

  @media (max-width: 991px) {
    height: 130px;
    margin: 0 auto;
  }
}

.selected-programs__item-content {
  flex: 0 0 85%;
  width: 85%;

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    svg {
      opacity: 0.7;
      margin-right: 5px;
    }
  }
}
