.competentions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  .competention-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition: transform 0.3s ease-in-out;
    border-radius: 0.2rem;
  }

  .competention-item__name {
    text-align: center;
    margin: 0;
    padding: 20px 10px;
  }

  .competention-item__btns {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    row-gap: 10px;
    column-gap: 10px;

    padding: 0 10px 10px;
  }
}
