.not-found {
  min-height: calc(100vh - 268px);
  display: flex;
  align-items: center;
  text-align: center;

  .title {
    font-size: 7em;
    font-weight: bold;
    margin: 0;
  }

  .description {
    font-size: 5em;
    margin: 0;
  }
}
