.events-type {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  column-gap: 30px;
  row-gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  .events-type__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition: transform 0.3s ease-in-out;
    border-radius: 0.2rem;
    padding: 10px;
    text-align: center;
  }

  .events-type__item-name {
    margin-bottom: 10px;
  }

  .events-type__item-btns {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    row-gap: 10px;
    column-gap: 10px;
  }
}
