.recruiting-form__title {
  text-align: center;
  margin: 20px 0;

  h5 {
    margin: 0;
    margin-bottom: 20px;
  }

  hr {
    width: 300px;
    max-width: 80%;
    height: 1px;
    border: none;
    background-color: #d4d4d5;
    margin: 0 auto;
  }
}
